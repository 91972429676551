<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <div class="d-flex justify-content-between align-items-center">
          <label for="patymentTerms">PASARELA HABILITADA?</label>
          <b-form-checkbox
            id="patymentTerms"
            v-model="flagPasarela"
            switch
            @change="togglePasarela"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormCheckbox,
  },
  data: () => ({
    isBusy: false,
    isToggleSidebar: false,
    idFlag: 4,
    flagPasarela: false,
  }),
  async mounted() {
    await this.listarRegistros()
  },
  methods: {
    async listarRegistros() {
      this.isBusy = true
      await store
        .dispatch('seguridad/PARAMETRO_GET_FLAG', {
          idFlag: this.idFlag,
        })
        .then(response => {
          if (response) {
            this.flagPasarela = response === 'ACTIVE'
          }
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
      this.isBusy = false
    },
    async updateFlag() {
      const service = 'seguridad/PARAMETRO_UPDATE'
      const formData = {
        idParametro: this.idFlag,
        valor: this.flagPasarela ? 'ACTIVE' : 'NOT_ACTIVE',
      }
      store.dispatch(service, formData)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
    },
    async togglePasarela() {
      await this.updateFlag()
    },
  },
}
</script>
